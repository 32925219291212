<template>
  <div class="modal-gestion-appel" :class="{ regie: isFromRegie }">
    <div v-if="isFromRegie" class="modal-gestion-appel-wrapper">
      <RegieForm
        :enableFastForm="fastFormEnabled"
        @created="handleRegieFormCreated"
        :currentType="currentType"
      />
    </div>
    <div v-else class="modal-gestion-appel-wrapper">
      <div class="title-modal border-bottom-solid">
        <span class="font-sz-14 bold-700 font-text color-black">
          {{ $i18n.locale === 'fr' ? 'Gestion des appels' : 'Call management' }}
        </span>
      </div>

      <div class="ml-5 mr-5 mt-10 mb-10 content-container">
        <div class="content-wrapper">
          <div class="Content-wrapper-info">
            <div class="icon-and-info">
              <div class="buttonIcon">
                <v-btn class="btn-check mr-2" color="#45148F" dark small>
                  <v-icon class="sz-icon-check">fa-badge-check</v-icon>
                </v-btn>
              </div>

              <div class="title-and-phone-container">
                <!-- Titre -->
                <span class="title">Louis Rodriguez</span>
                <!-- Numéro de téléphone -->
                <span class="phone-number">+22687965478</span>
              </div>

              <div class="btn-call-contact">
                <v-btn
                  class="btn-Note mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleIconNote('fa-note')"
                >
                  <v-icon class="sz-icon-check">fal fa-note</v-icon>
                </v-btn>
                <v-btn
                  class="btn-Pause mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleIconPause('fa-pause')"
                >
                  <v-icon class="sz-icon-check"> fa-pause</v-icon>
                </v-btn>
                <v-btn
                  class="btn-Micro mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleMicrophone()"
                >
                  <v-icon class="sz-icon-check">{{
                    isMicrophoneMuted
                      ? 'fal fa-microphone'
                      : 'fal fa-microphone-slash'
                  }}</v-icon>
                </v-btn>

                <!-- <v-btn class="btn-Phone " color="#45148F" dark small @click="togglePhone()">
                                    <v-icon class="sz-icon-check">{{ isCallActive ? 'fa-phone' : 'fa-phone-slash' }}</v-icon>
                                </v-btn> -->
                <v-btn
                  class="btn-Phone-Xmark"
                  color="#45148F"
                  dark
                  small
                  @click="togglePhoneXmark('fal fa-phone-xmark')"
                >
                  <v-icon class="sz-icon-check">fal fa-phone-xmark</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-divider class="mb-2 mt-2"></v-divider>

          <!-- État et date -->
          <div class="status-and-date">
            <span class="Statu-Appel">Répondu</span>
            <span class="Date-appel"> Le 21/12/2023 - 13:27 </span>
          </div>
        </div>
      </div>

      <div class="ml-5 mr-5 mt-10 mb-10 content-container">
        <div class="content-wrapper">
          <div class="Content-wrapper-info">
            <div class="icon-and-info">
              <div class="buttonIcon">
                <v-btn class="btn-check2 mr-2" color="#45148F" dark small>
                  <v-icon class="sz-icon-check">fal fa-phone-slash</v-icon>
                </v-btn>
              </div>

              <div class="title-and-phone-container">
                <!-- Titre -->
                <span class="title">Louis Rodriguez</span>
                <!-- Numéro de téléphone -->
                <span class="phone-number">+22687965478</span>
              </div>

              <div class="btn-call-contact">
                <v-btn
                  class="btn-Note mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleIconNote('fa-note')"
                >
                  <v-icon class="sz-icon-check">fal fa-note</v-icon>
                </v-btn>
                <v-btn
                  class="btn-Pause mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleIconPause('fa-pause')"
                >
                  <v-icon class="sz-icon-check"> fa-pause</v-icon>
                </v-btn>
                <v-btn
                  class="btn-Micro mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleMicrophone()"
                >
                  <v-icon class="sz-icon-check">{{
                    isMicrophoneMuted
                      ? 'fal fa-microphone'
                      : 'fal fa-microphone-slash'
                  }}</v-icon>
                </v-btn>

                <!-- <v-btn class="btn-Phone " color="#45148F" dark small @click="togglePhone()">
                                    <v-icon class="sz-icon-check">{{ isCallActive ? 'fa-phone' : 'fa-phone-slash' }}</v-icon>
                                </v-btn> -->
                <v-btn
                  class="btn-Phone-Xmark"
                  color="#45148F"
                  dark
                  small
                  @click="togglePhoneXmark('fal fa-phone-xmark')"
                >
                  <v-icon class="sz-icon-check">fal fa-phone-xmark</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-divider class="mb-2 mt-2"></v-divider>

          <!-- État et date -->
          <div class="status-and-date">
            <span class="Statu-Appel">Répondu</span>
            <span class="Date-appel"> Le 21/12/2023 - 13:27 </span>
          </div>
        </div>
      </div>

      <div class="ml-5 mr-5 mt-10 mb-10 content-container">
        <div class="content-wrapper">
          <div class="Content-wrapper-info">
            <div class="icon-and-info">
              <div class="buttonIcon">
                <v-btn class="btn-check3 mr-2" color="#45148F" dark small>
                  <v-icon class="sz-icon-check">fa-phone-volume</v-icon>
                </v-btn>
              </div>

              <div class="title-and-phone-container">
                <!-- Titre -->
                <span class="title">Louis Rodriguez</span>
                <!-- Numéro de téléphone -->
                <span class="phone-number">+22687965478</span>
              </div>

              <div class="btn-call-contact">
                <v-btn
                  class="btn-Note mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleIconNote('fa-note')"
                >
                  <v-icon class="sz-icon-check">fal fa-note</v-icon>
                </v-btn>
                <v-btn
                  class="btn-Pause mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleIconPause('fa-pause')"
                >
                  <v-icon class="sz-icon-check"> fa-pause</v-icon>
                </v-btn>
                <v-btn
                  class="btn-Micro mr-1"
                  color="#45148F"
                  dark
                  small
                  @click="toggleMicrophone()"
                >
                  <v-icon class="sz-icon-check">{{
                    isMicrophoneMuted
                      ? 'fal fa-microphone'
                      : 'fal fa-microphone-slash'
                  }}</v-icon>
                </v-btn>

                <!-- <v-btn class="btn-Phone " color="#45148F" dark small @click="togglePhone()">
                                    <v-icon class="sz-icon-check">{{ isCallActive ? 'fa-phone' : 'fa-phone-slash' }}</v-icon>
                                </v-btn> -->
                <v-btn
                  class="btn-Phone-Xmark"
                  color="#45148F"
                  dark
                  small
                  @click="togglePhoneXmark('fal fa-phone-xmark')"
                >
                  <v-icon class="sz-icon-check">fal fa-phone-xmark</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-divider class="mb-2 mt-2"></v-divider>

          <!-- État et date -->
          <div class="status-and-date">
            <span class="Statu-Appel">Répondu</span>
            <span class="Date-appel"> Le 21/12/2023 - 13:27 </span>
          </div>
        </div>
      </div>

      <!-- ACTIONS BTN -->
      <div class="action-new-projet">
        <!-- <v-btn color="#5C2DD3" dark :loading="loading" @click="addProject">
            {{ $i18n.locale === 'fr' ? 'Ajouter le projet' : 'Add the project'
            }}<v-icon class="ml-1">mdi-chevron-right</v-icon>
          </v-btn> -->
        <v-btn
          text
          class="ml-3"
          @click="handleClose"
          variant="outline-secondary"
        >
          {{ $t('btnCancel') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'Model-Gestion-Appels',
  components: {
    RegieForm: () => import('@/views/Projects/modals/RegieForm.vue')
  },
  props: {
    currentType: {
      required: true
    }
  },
  data() {
    return {
      isMicrophoneMuted: 'fa-microphone',
      isCallActive: false, // État de l'appel (si un appel est actif ou non)
      projectToAdd: {},
      contactToAdd: {},
      selectedContact: null,
      contactRequiredError: false,
      contactState: 'old',
      loading: false,
      premierStage: null,
      premierSubstage: null
    }
  },
  methods: {
    ...mapActions([
      'createProject',
      'searchContacts',
      'resetSearchContacts',
      'resetProjectError',
      'fetchEtapes',
      'fetchEtapeStage',
      'fetchEtapeSubstage'
    ]),
    //     toggleMicrophone() {
    //     this.isMicrophoneMuted = !this.isMicrophoneMuted;
    // },
    // togglePhone() {
    //   // Méthode pour basculer l'état de l'appel
    //   this.isCallActive = !this.isCallActive;
    // },

    async changePipelineSelonType(type_id) {
      if (type_id) {
        await this.fetchEtapes({ type_id: type_id, isNotLoading: true }).then(
          async () => {
            let etapeAvecSubStage = null
            let subStagesRemplis = null

            for (const etape of this.getEtapes) {
              subStagesRemplis = [] // Réinitialiser la liste des sub-stages remplis pour chaque nouvelle étape
              for (const stage of etape.stages) {
                await this.fetchEtapeSubstage({
                  cstage_id: stage.id,
                  stage: stage
                }) // Appel de l'API pour remplir les sub-stages de chaque stage de chaque étape

                if (stage.subStages.length > 0) {
                  etapeAvecSubStage = stage
                  subStagesRemplis = stage.subStages // Stocker les sub-stages remplis du stage actuel
                  break // Sortir de la boucle si les sub-stages de ce stage ne sont pas vides
                }
              }

              if (etapeAvecSubStage) {
                break // Sortir de la boucle externe si des sub-stages non vides sont trouvés dans une étape
              }
            }
            this.premierStage = etapeAvecSubStage
            this.premierSubstage =
              subStagesRemplis && subStagesRemplis.length
                ? subStagesRemplis[0]
                : subStagesRemplis
          }
        )
      }
    },
    async addProject() {
      this.resetProjectError()
      if (this.$refs.addNewProjet.validate()) {
        this.loading = true
        this.contactRequiredError = false
        if (this.contactState == 'old' && !this.selectedContact) {
          this.contactRequiredError = true
          return
        }
        const data = {}
        data.dtype_id = this.projectToAdd.type
        if (this.getEtapes && this.getEtapes.length) {
          if (this.getEtapes[0].type == data.dtype_id) {
            data.step_id = this.getEtapes[0].id
            if (this.premierStage) {
              data.cstage_id = this.premierStage.id
            }
            if (this.premierSubstage) {
              data.csubstage_id = this.premierSubstage.id
            }
          }
        }
        if (this.contactState == 'old') {
          data.contact_id = this.selectedContact.id
          data.title = this.selectedContact.full_name
        } else {
          data.contact = this.contactToAdd
          data.title = `${this.contactToAdd.first_name} ${this.contactToAdd.last_name}`
        }
        const response = await this.createProject({
          currentType: this.currentType,
          data
        })
        if (response) {
          this.resetNewProject()
          this.$emit('created', this.getProjectsLastCreated)
        }
        this.loading = false
      }
    },

    handleRegieFormCreated() {
      this.$emit('created', this.getProjectsLastCreated)
    },

    resetNewProject() {
      this.projectToAdd = {}
      this.contactToAdd = {}
      this.selectedContact = null
      this.contactRequiredError = false
      this.contactState = 'old'
      this.resetSearchContacts()
    },
    asyncSearchContact: debounce(function(query) {
      if (query && query.length > 1) {
        this.searchContacts(query)
      }
    }, 400),
    handleClose() {
      this.$emit('close')
      this.$refs.addNewProjet.resetValidation()
      this.resetProjectError()
    }
  },
  computed: {
    ...mapGetters([
      'getEtapes',
      'getProjectsCreateError',
      'getProjectsLastCreated',
      'getContactsList',
      'getContactsProcessing',
      'getProjectsProcessing',
      'getProjectsTypes',
      'currentUser',
      'getProjectsTypesLoading'
    ]),

    fastFormEnabled: function() {
      if (this.currentUser.details && this.currentUser.details.see_form_fast) {
        return true
      }
      return false
    },
    isFromRegie: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type == 'regie'))
      ) {
        return true
      }
      return false
    },
    visibilityProjectsTypes: function() {
      return this.getProjectsTypes
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
}

.content-wrapper {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px;
  flex: 1;
}

.Content-wrapper-info {
  display: flex;
}

.icon-and-info {
  display: flex;
}

.btn-call-contact {
  margin-left: 11rem;
  border-radius: 4px !important;
}

.btn-Note,
.btn-Pause,
.btn-Micro,
.btn-Phone-Xmark,
.btn-Phone {
  /* Styles communs à toutes les classes */
  min-width: 30px !important;
  width: 10px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background-color: #f6f6f6 !important;
  box-shadow: none !important;
  border: 1px solid #e5e5e5 !important;
  color: #bebebe;
}

.title-and-phone-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  color: #000000;
  font-weight: bold;
  font-size: 11px !important;
  font-family: 'Montserrat';
  line-height: 0px !important;
  margin-top: 8px;
  margin-bottom: 5px;
}

.phone-number {
  color: #000000;
  font-size: 11px !important;
  margin-top: 2px;
  letter-spacing: 1px;
}

.btn-check {
  min-width: 30px !important;
  width: 10px !important;
  border-radius: 4px !important;
  background-color: #0cb377 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  box-shadow: none !important;
}

.btn-check2 {
  min-width: 30px !important;
  width: 10px !important;
  border-radius: 4px !important;
  background-color: #e02d17 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  box-shadow: none !important;
}

.btn-check3 {
  min-width: 30px !important;
  width: 10px !important;
  border-radius: 4px !important;
  background-color: #0cb377 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  box-shadow: none !important;
}

.Statu-Appel {
  background-color: rgba(12, 178, 119, 0.1) !important;
  color: #0cb277;
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 11px;
}

.Date-appel {
  font-size: 10px;
  margin-left: 3px;
}

.sz-icon-check {
  height: 13px;
}

.modal-gestion-appel {
  position: fixed;
  height: calc(100% - 0px);
  width: 500px;
  top: 0px;
  right: 0;
  z-index: 101;

  .modal-gestion-appel-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    // padding: 30px;
    font-size: 13px;
    color: #5e5e5e;

    .title-modal {
      padding: 20px;
      background-color: #f6f6f6;
    }

    .action-new-projet {
      float: right;
    }
  }

  &.regie {
    width: 800px;
    max-width: 100%;
    min-width: 400px;

    .modal-gestion-appel-wrapper {
      overflow-y: auto;
    }
  }
}
</style>
